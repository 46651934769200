import React, {useEffect} from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import {useForm} from "react-hook-form";
import {loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha} from 'react-simple-captcha';

const MeterTestRequestForm = () => {
    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);

    const onSubmit = data => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        if (!validateCaptcha(user_captcha_value) === true) {
            alert('Captcha Does Not Match');
            return
        }

        let formdata = new FormData();
        formdata.append("username", data.name);
        formdata.append("accountNumber", data.accountNumber);
        formdata.append("email-address", data.emailAddress);
        formdata.append("phoneNumber", data.phoneNumber);
        formdata.append("address", data.address);
        formdata.append("apartment", data.apartment);
        formdata.append("city", data.city);
        formdata.append("state", data.state);
        formdata.append("zip", data.zip);
        formdata.append("meterNumber", data.meterNumber);
        formdata.append("instructions", data.instructions);
        formdata.append("_wpcf7_unit_tag", 'wpcf7-f500-o1');

        const requestOptions = {
            method: 'POST',
            body: formdata,
        };

        fetch(`${process.env.GATSBY_API_URL}wp-json/contact-form-7/v1/contact-forms/500/feedback`, requestOptions)
            .then(response => response.json())
            .then(() => {
                alert('Form submitted successfully.')
                reset();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <Layout>
            <Seo title="Meter Test Request Form" description="SEIEC Meter Test Request Form"/>
            <div className="w-full px-8">
                <h2 className="item-title">Meter Test Request Form</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="overflow-hidden sm:rounded-md">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    {...register("name", {required: true})}
                                    name="name"
                                    id="name"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.name?.type === 'required' &&
                                <span className="error text-red">Name is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700">
                                    Account Number
                                </label>
                                <input
                                    type="text"
                                    {...register("accountNumber", {required: true})}
                                    name="accountNumber"
                                    id="accountNumber"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.accountNumber?.type === 'required' &&
                                <span className="error text-red">Account Number is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <input
                                    type="email"
                                    {...register("emailAddress", {required: true})}
                                    name="emailAddress"
                                    id="email-address"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.emailAddress?.type === 'required' &&
                                <span className="error text-red">Email address is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                                    Phone Number
                                </label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    {...register("phoneNumber", {required: true})}
                                    id="phoneNumber"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.phoneNumber?.type === 'required' &&
                                <span className="error text-red">Phone Number is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    name="address"
                                    {...register("address", {required: true})}
                                    id="address"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.address?.type === 'required' &&
                                <span className="error text-red">Address is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="apartment" className="block text-sm font-medium text-gray-700">
                                    Apartment or Unit #
                                </label>
                                <input
                                    type="text"
                                    name="apartment"
                                    id="apartment"
                                    {...register("apartment")}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <input
                                    type="text"
                                    name="city"
                                    {...register("city", {required: true})}
                                    id="city"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.city?.type === 'required' &&
                                <span className="error text-red">City is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                                    State
                                </label>
                                <input
                                    type="text"
                                    name="state"
                                    id="state"
                                    {...register("state", {required: true})}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.state?.type === 'required' &&
                                <span className="error text-red">State is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                                    ZIP / Postal code
                                </label>
                                <input
                                    type="text"
                                    name="zip"
                                    id="zip"
                                    {...register("zip", {required: true})}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.zip?.type === 'required' &&
                                <span className="error text-red">ZIP / Postal code is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="meterNumber" className="block text-sm font-medium text-gray-700">
                                    Meter Number
                                </label>
                                <input
                                    type="text"
                                    name="meterNumber"
                                    id="meterNumber"
                                    {...register("meterNumber", {required: true})}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.meterNumber?.type === 'required' &&
                                <span className="error text-red">Meter Number is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="instructions" className="block text-sm font-medium text-gray-700">
                                    Any Additional Instructions?
                                </label>
                                <textarea
                                    name="instructions"
                                    id="instructions"
                                    {...register("instructions")}
                                    rows={4}
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <label htmlFor="workPhoneNumber" className="block text-sm font-medium text-gray-700">
                                    Enter text in field below for validation.
                                </label>
                                <LoadCanvasTemplate/>
                                <input
                                    type="text"
                                    name="captcha"
                                    {...register("captcha", {required: true})}
                                    id="user_captcha_input"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.captcha?.type === 'required' &&
                                <span className="error text-red">Captcha is required</span>}
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default MeterTestRequestForm
